<template>
  <el-dialog
    id="ExportToXLSDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @open="onBeforeShow"
    @opened="onShow"
    @close="onBeforeClose"
    destroy-on-close
    append-to-body
    @keydown.enter.native="onCommit"
  >
    <el-row>
      <el-col>
        <center>
          <p>Файл створено успішно.</p>
          <p>Для завантаження файла натисніть кнопку "Завантажити"</p>
        </center></el-col
      >
    </el-row>
    <span slot="footer" class="dialog-footer">
      <JsonExcel :data="model.data" worksheet="Sheet1" :name="model.fileName">
        <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">Завантажити</el-button>
      </JsonExcel>
    </span>
  </el-dialog>
</template>

<script>
  import { bus } from "@/main";
  import { _ } from "vue-underscore";
  import JsonExcel from "vue-json-excel";

  export default {
    name: "ExportToXLS",
    components: { JsonExcel },
    props: ["busEvent"],
    data() {
      var that = this;
      return {
        dialogShow: false,
        title: $tt("Експорт в XLSX"),
        model: {},
        initModel: {},
      };
    },

    methods: {
      onCommit() {
        this.dialogShow = false;
      },
      onBeforeShow() {},
      onShow() {
        this.model = _.extend({}, this.initModel);
      },
      onBeforeClose() {},
    },
    created() {
      bus.$on(this.busEvent + ".show", (data) => {
        this.title = (data && data.title) || this.title;
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.dialogShow = true;
      });
      bus.$on(this.busEvent + ".hide", () => {
        this.dialogShow = false;
      });
    },
    computed: {},
  };
</script>
<style lang="scss">
  #ExportToXLSDialog {
    .el-dialog {
      max-height: 240px !important;
    }
    .button-in-form {
      margin-bottom: 16px;
    }
    .el-row {
      margin-bottom: 20px;
    }
  }
</style>
